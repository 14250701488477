.sign_up_form.ant-form {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
}

.footer_link {
  text-align: left;
  margin-top: 10px;
}

.ant-list-item.organization-list-item {
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
  position: relative;
}

.ant-list-item.organization-list-item i {
  position: absolute;
  top: 20px;
  right: -18px;
  cursor: pointer;
}

.organization-add-button {
  margin-bottom: 25px;
}

.capthca {
  width: 100%;
  text-align: right;
}

.capthca > * > * > * {
  display: inline-block;
}