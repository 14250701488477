.organization-list-item {
  border-top: 1px solid #e8e8e8;
  padding-top: 25px;
  border-bottom: none;
  position: relative;
}

.organization-list-item i {
  position: absolute;
  top: 37px;
  right: -18px;
  cursor: pointer;
}

.organization-add-button {
  width: 100%;
}