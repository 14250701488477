.main {
  width: 50%;
  margin: auto;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.year {
  padding: 5px;
  outline: none;
  border: 1px solid silver;
  border-radius: 5px;
}

.getBtn {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
}
