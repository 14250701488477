.where-container {
  position: relative;
  height: calc(100vh - 109px);
}

.where-container .leaflet-tile-container {
  filter: grayscale(100%);
}

.where-container .leaflet-control-layers {
  display: none;
}

.where-container .leaflet-marker-icon.leaflet-div-icon {
  background: #fff;
  border: 1px solid #7d7d7d;
}

button.where-button {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  border-radius: 0;
  background-color: #e0c592;
  border-color: #e0c592;
  font-size: 18px;
  height: 45px;
}

button.where-button:hover,
button.where-button:focus {
  background-color: #eed39e;
  border-color: #eed39e;
  box-shadow: 0 0 0 2px rgba(224, 197, 146, 0.4);
}

.where-button-container {
  display: flex;
  justify-content: center;
}
