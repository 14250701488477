.bolgar-categories-main {
  color: #fff;
  width: 1300px;
  margin: 33px auto;
  padding: 0 15px;
  user-select: none;
}

.bolgar-categories-main header {
  text-align: center;
}

.bolgar-categories-main h1 {
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 48px;
  text-transform: uppercase;
}

.bolgar-categories-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bolgar-category {
  width: 266px;
  height: 147px;
  margin-left: 20px;
  margin-top: 45px;
  background: rgba(240, 238, 237, 0.7);
  background-image: url('./img/webp-converted/border1.webp');
  background-size: cover;
  color: black;
  opacity: 0.8;
  cursor: pointer;
}

.bolgar-category:hover {
  background: rgba(240, 238, 237, 1.3);
  background-image: url('./img/webp-converted/border2.webp');
  background-size: cover;
}

.bolgar-category-img {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  height: 70px;
  object-fit: scale-down;
}

.bolgar-category-text {
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
}
