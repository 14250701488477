.header-unauth {
  text-align: right;
  padding: 0 10px;
}

.header-container {
  position: relative;
  display: flex;
  width: 100vw;
  overflow: hidden;
  flex-wrap: nowrap;
  height: 50px;
}

.dark.header-container .ant-menu-vertical {
  text-align: center;
}

.dark.header-container > .anticon {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 20px;
  z-index: 10;
  padding: 10px;
}

.dark.header-container > .header-main-link {
  display: none;
}

.main-link {
  font-family: 'Roboto Slab', serif;
  font-size: 18px;
  text-transform: uppercase;
}

.dark .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.dark .ant-menu-horizontal > .ant-menu-item:hover,
.dark .ant-menu-horizontal > .ant-menu-submenu:hover,
.dark .ant-menu-horizontal > .ant-menu-item-active,
.dark .ant-menu-horizontal > .ant-menu-submenu-active,
.dark .ant-menu-horizontal > .ant-menu-item-open,
.dark .ant-menu-horizontal > .ant-menu-submenu-open,
.dark .ant-menu-horizontal > .ant-menu-item-selected,
.dark .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-top: 2px solid #e0c592;
  border-bottom: 2px solid transparent;
  color: #fff;
}

.dark .ant-menu-horizontal {
  border-bottom: none;
  background-color: #5b514c;
}

.is-main .ant-menu-horizontal {
  background-color: transparent;
}

.dark .ant-menu-horizontal > .ant-menu-item > button,
.dark .ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
  opacity: 0.6;
}

.dark .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected > a,
.dark .ant-menu-horizontal > .ant-menu-item > button:hover,
.dark .ant-menu-horizontal > .ant-menu-item > button:focus,
.dark .ant-menu-horizontal > .ant-menu-item > a:hover,
.dark .ant-menu-horizontal > .ant-menu-item > a:focus {
  outline: none;
  color: #fff;
  opacity: 1;
}

.dark .ant-menu-horizontal .ant-select-selection {
  background: transparent;
  color: #fff;
  border: none;
  opacity: 0.6;
}

.dark .ant-menu-horizontal .ant-select-open .ant-select-selection,
.dark .ant-menu-horizontal .ant-select-focused .ant-select-selection,
.dark .ant-menu-horizontal .ant-select-selection:focus,
.dark .ant-menu-horizontal .ant-select-selection:active{
  box-shadow: none;
}

.dark .ant-menu-horizontal .ant-select-arrow {
  color: #fff;
  opacity: 0.6;
}

.dark .ant-menu-horizontal .ant-select-selection-selected-value {
  text-transform: uppercase;
}

.right-submenu {
  flex: 0 0 320px;
  padding-right: 30px;
}

.center-submenu {
  flex: 1 5 300px;
  padding-left: 30px;
  overflow: hidden;
}

@media (max-width: 820px) {
  .header-container {
    display: block;
    min-height: 48px;
  }

  .header-container.opened {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 8;
    height: 100vh;
  }

  .header-container > .anticon {
    display: block;
  }

  .header-container.opened > .anticon {
    position: fixed;
  }

  .header-container > .header-main-link {
    display: block;
  }

  .header-container > .ant-menu-horizontal {
    display: none;
  }

  .header-container.opened > .header-main-link {
    display: none;
  }

  .header-container .ant-menu-vertical {
    text-align: center;
    overflow: auto;
    // height: 100%;

    &.right-submenu {
      padding-bottom: 20px;
    }
  }
  .right-submenu {
    padding: 0;
  }
  .center-submenu {
    padding: 0;
  }
}

.left-top-menu-item {
  padding: 0 15px;
}

.ant-menu-submenu-title{
  position: relative;
  top: 2px;
  color: #fffeee;
  font-size: 22px;
}
