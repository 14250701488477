.simple-multiple-input {
  line-height: 25px;

  .ant-tag-checkable {
    margin: 0px 1px;
    border: #cccccc solid 1px;


    &.artifact-material {
      border-color: #cccccc;
      background: rgba(233, 233, 233, 0.3);
    }
    &.artifact-material-1 {
      border-color: #cccccc;
      background: rgba(208, 208, 208, 0.1);
    }
    &.artifact-material-2 {
      border-color: #669933;
      background: rgba(102, 153, 51, 0.2);
    }
    &.artifact-material-3 {
      border-color: #faebd7;
      background: rgba(250, 235, 215, 0.5);
    }
    &.artifact-material-4 {
      border-color: #6699CC;
      background: rgba(102, 153, 204, 0.1);
    }
    &.artifact-material-5 {
      border-color: #cccc99;
      background: rgba(204, 204, 153, 0.1);
    }
    &.artifact-material-6 {
      border-color: #cc6666;
      background: rgba(204, 102, 102, 0.1);
    }
  }

  .ant-tag-checkable-checked {
    border: #ffffff solid 1px;

    &.artifact-material {
      border-color: Black;
      color: black;
      background: rgb(233, 233, 233);
    }
    &.artifact-material-1 {
      border-color: #cccccc;
      color: black;
      background: rgb(208, 208, 208);
    }
    &.artifact-material-2 {
      border-color: #669933;
      background: rgb(102, 153, 51);
    }
    &.artifact-material-3 {
      border-color: #faebd7;
      color: black;
      background: rgb(250, 235, 215);
    }
    &.artifact-material-4 {
      border-color: #6699CC;
      background: rgb(102, 153, 204);
    }
    &.artifact-material-5 {
      border-color: #cccc99;
      color: black;
      background: rgba(204, 204, 153, 0.7);
    }
    &.artifact-material-6 {
      border-color: #cc6666;
      background: rgb(204, 102, 102);
    }
  }
}

.file-upload-fields {
  border: #aaaaaa dashed 1px;
  border-radius: 5px;
  margin-bottom: 5px;
}
