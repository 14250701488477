.ant-notification.ant-notification-topRight {
  width: 500px;
}

.existing-user-modal .ant-card-head-wrapper{
  display: block;
}

.existing-user-modal .ant-card-head-wrapper .ant-card-head-title {
  padding-bottom: 0;
}

.ant-notification.ant-notification-topRight .ant-card-body{
  display: none;
}

span.existing-status {
  color: #ff0000c4;
  margin: 0 5px;
  font-size: 12px;
}

.existing-user-card .ant-list-item-meta-description a {
  display: inline-block;
}
