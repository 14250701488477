.loading-subject {
  position: absolute;
  top: 9px;
  right: 30px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: border 0.3s;

  & > i {
    padding: 10px;
  }

  &:hover {
    border: 2px solid #e8e8e8;
  }

  &-content {
    max-width: 500px;

    .ant-divider-horizontal.ant-divider-with-text, .ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right {
      margin: 5px 0;
    }
  }

  &-title {
    text-align: center;
    font-size: 18px;
    color: #575757c2;
  }

  &-list {

    .ant-collapse > .ant-collapse-item.custom_panel .ant-collapse-content > .ant-collapse-content-box {
      padding: 10px;
    }

    .ant-tag {
      margin-bottom: 5px;
      padding: 5px 10px;
      width: 100%;
      white-space: initial;
    }

    &-item {
      display: flex;
      align-items: center;

      span {
        flex: 1;
      }
    }
  }
}