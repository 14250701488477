.avatar-uploader > .ant-upload img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.avatar-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-upload i.anticon.anticon-close {
  font-size: 20px;
  cursor: pointer;
}

.ant-upload-picture-card-wrapper {
  flex: 0;
}