.adv-search-field{
  &.select {
    display: flex;
    align-items: center;

    .field-remove-button {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}