.gallery-slide {
  overflow: hidden;
}

.gallery-slide img{
  max-height: 300px;
  margin: 0 auto;
}

.gallery-field {
  margin-bottom: 30px;
}