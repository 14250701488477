.ant-tabs-content {
  padding: 0px 15px;
}

.ant-list-item-meta-description a {
  display: block;
}

.ant-tabs-tab span.ant-badge {
  margin-left: 5px;
}

.ant-spin-container .ant-list-empty-text {
  padding: 0;
}

.description-icon {
  margin-left: 5px;
    color: #a2a2a2;
}

.ant-list-item-meta-description a {
  display: inline;
}

.ant-list-item-meta-description a.block-link {
  display: block;
  float: left;
}
