.bolgar-index-bg {
  background-color: rgba(0,0,0,0) !important;
  height: 100% !important;
  width: 100% !important;
}

.bolgar-index-bg-img {
  opacity: 1 !important;
  width: 107% !important;
  height: 100% !important;
}

.bolgar-index-main {
  margin-top: 150px !important;
}

.bolgar-button {
  background: rgba(0,0,0,0) !important;
  border-radius: 0px !important;
  border: solid 2px white !important;
  width: 200px !important;
  height: 40px !important;
  padding-top: 3px !important;
  color: white !important;
  text-align: center !important;
}

.bolgar-button:hover {
  color: black !important;
  border: solid 2px white !important;
  background: rgba(255,255,255,0.8) !important;
}

.bolgar-index-left-button {
  margin-left: 32%;
}

.bolgar-index-right-button {
  margin-left: 15px;
}

.bolgar-button img.svg{
  fill: black;
  color: black;
  width: 30px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-left: 5px;
  margin-top: -9px;
  left: 0;
  transition: all 0.3s;
}
