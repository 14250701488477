.adv-search-field {
  position: relative;
  text-align: left;
  vertical-align: top;
  margin: 10px 0;
  background-color: rgba(179, 177, 177, 0.1);
  padding: 10px;

  .ant-checkbox-wrapper {
    vertical-align: -webkit-baseline-middle;
  }

  .field-remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    filter: grayscale(100);
    opacity: 0.2;
    transition: all 0.3s;
  }

  &:hover > .field-remove-button {
    filter: grayscale(0);
    opacity: 1;
  }

  &.not_choosen {
    display: flex;
    align-items: center;
  }
}

.adv-search-field > * {
  display: inline-block;
  vertical-align: text-top;
}
