.when-container {
  position: relative;
  width: 1150px;
  margin: auto;
}

.when-container-slider {
  width: 1100px;
  float: right;
}

button.when-button {
  z-index: 1000;
  bottom: 10px;
  border-radius: 0;
  background-color: #e0c592;
  border-color: #e0c592;
  font-size: 18px;
  height: 40px;
  margin-top: 20px
}

button.when-button:hover,
button.when-button:focus {
  background-color: #eed39e;
  border-color: #eed39e;
  box-shadow: 0 0 0 2px rgba(224, 197, 146, 0.4);
}

.when-button-container {
  text-align: center;
}
