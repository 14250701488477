.common-field-text {
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-wrap;
}

.edit-icon {
  margin-left: 30px;
  cursor: pointer;
}

.ant-select.common-field-text-edit {
  width: 50%;
  float: left;
}

.ant-input.common-field-text-edit {
  width: 50%;
}
