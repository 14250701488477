.ling-index-bg {
  top: 0;
  position: absolute;
  z-index: -1;
  width: 100vw;
  overflow: hidden;
  height: 456px;
  perspective: 4000px;
}

.ling-index-bg .cd-floating-background {
  transform-style: preserve-3d;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.2s;
}

.ling-index-bg .people {
  position: absolute;
  /*transform: translateZ(50px);*/
  backface-visibility: hidden;
  transition: all 0.5s ease-out;
}

.ling-index-bg .ling-index-bg-img {
  opacity: .9;
  width: 110%;
  height: 110%;
  object-fit: cover;
  left: -5%;
  position: relative;
  top: -5%;
}

.ling-index-research-link {
  color: #e0c592;
  text-decoration: underline;
}

.ling-index-research-link:hover {
  color: #e0c592;
  text-decoration: none;
  opacity: 0.8;
}

.ling-index main{
  color: #fff;
  width: 1200px;
  margin: 33px auto;
  padding: 0 15px;
}

.ling-index main header{
  text-align: center;
}

.ling-index h1{
  color: #fff;
  font-family: 'Roboto Slab', serif;
  font-size: 48px;
  text-transform: uppercase;
}

.ling-index .tile{
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  box-shadow: 0px 5px 20px rgba(91, 81, 76, 0.3);
  transition: all 0.3s;
}

.ling-index .tile:hover {
  box-shadow: 0px 5px 20px 4px rgba(91, 81, 76, 0.3);
}

.ling-index .tile img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #fff;
}

.ling-index .short-column {
  margin-top: 48px;
  width: 100%;
  display: inline-block;
}

.ling-index .short-column .ling-tile {
  height: 245px;
}

.ling-index .short-column .ling-tile img{
  top: inherit;
  bottom: 0;
}

.ling-index .ling-what {
  cursor: default;
  background-color: #fff;
}

.ling-index h3{
  font-family: Roboto Slab,serif;
  text-transform: uppercase;
  float: left;
  font-size: 18px;
  color: #fff;
  background-color: #e0c592;
  display: inline-block;
  padding: 10px 30px;
}

.ling-index .search-block {
  margin: 46px 0;
}

.ling-index .search-block .ant-select-selection--single{
  height: 46px;
  background-color: transparent;
  color: #fff;
}

.ling-index .search-block .ant-select-open .ant-select-selection {
  box-shadow: none;
  border-color: #fff;
}

.ling-index .search-block .ant-select-focused .ant-select-selection,
.ling-index .search-block .ant-select-selection:focus, .ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  border-color: #fff;
}

.ling-index .search-block .ant-select-arrow {
  color: #fff;
  opacity: 0.6;
}

.ling-index .search-block .ant-select-selection__rendered {
  line-height: 44px;
  margin-left: 30px;
}

.ling-index .search-block .ant-input-search input{
  color: #fff;
  height: 46px;
  background-color: transparent;
}

.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary{
  background-color: #e0c592;
  border-color: #e0c592;
  width: 48px;
  height: 46px;
  border-radius: 0;
  transition: opacity 0.3s;
}

.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary:hover,
.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary:focus {
  opacity: 0.8;
}

.ling-index .search-block .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection {
  border-radius: 0;
  border-right-width: 0 !important;
}

.ling-index .search-block .ant-select-selection:hover,
.ling-index .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fff;
  border-right-width: 0 !important;
}

.ling-index .search-block .ant-input:focus {
  border-color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}
