.add-spatials-modal-window {
  display: flex;
}

.add-spatials-modal-left-panel {
  width: 50%;
}

.add-spatials-modal-map-panel {
  width: 45%;
  height: 700px;
}

.ant-select {
  width: 100%;
}
