.user-confirm-dismiss {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.user-confirm-dismiss:hover {
  opacity: 0.8;
}

.user-confirm-dismiss:focus,
.user-confirm-dismiss:active{
  outline: none;
}

.user-confirm-confirm {
  background: transparent;
  border: none;
  color: blue;
  cursor: pointer;
}

.user-confirm-confirm:hover {
  opacity: 0.8;
}

.user-confirm-confirm:focus,
.user-confirm-confirm:active{
  outline: none;
}

.existing-user-card {
  text-align: left;
  padding: 0 15px;
}

.existing-user-card-list .ant-spin-nested-loading {
  border: 1px solid grey;
  border-radius: 5px;
}

.user-confirmation-block .ant-list-item-meta-description span{
  color: grey;
  font-size: 14px;
  margin-right: 5px;
}
