.map-panel {
  width: calc(50vw - 40px);
  height: 90vh !important;
  position: fixed;
  right: 40px;
  top: 60px;
}

.rel {
  position: fixed;
  left: calc(50% + 1em);
  top: 55px;
  width: calc(50% - 1.5em);
  height: calc(100vh - 110px);
  padding-right: 30px;
  z-index: 1;
}

.gallery-cont {
  position: fixed;
  left: calc(50% + 1em);
  top: 55px;
  width: calc(50% - 1.5em);
  height: calc(100vh - 110px);
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  padding-bottom: 200px;
}

.gallery-btn{
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
}

.gallery-img-cont{
  max-width: 400px;
  max-height: 400px;
}
.gallery-img{
  max-width: 100%;
  max-height: 100%;
}

.btnBlock {
  position: fixed;
  left: 48.5%;
  top: 185px;
}

.gallery{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.mapToggleButton {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  width: fit-content;
  border-radius: 5px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;

}

.map-tab-pane {
  height: 100%;

  .ant-tabs-content {
    height: 85%;
  }
}

.map-tab {
  height: 100%;
  width: 100%;
  border: 1px solid;
}

.sketchfab-embed-wrapper {
  width: 100%;
  height: 100%;
}

.sketchfab-embed-wrapper iframe {
  width: 100%;
  height: 100%;
}

.map-top-alert {
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
  position: absolute;
  margin-top: 10px;
}

.map-panel .ant-spin.ant-spin-spinning {
  position: absolute;
  top: 50px;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.39);
  padding: 100px;
  width: 100%;
  height: 100%;
}

.map-is-snapshot {
  /* position: relative; */
}
