.modal-container {
  position: relative;
}

.selection-modal {
  position: absolute;
  background-color: #fff;
  top: 115px;
  right: 500px;
  z-index: 5;
  min-width: 700px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 10px rgba(105, 105, 105, 0.15), 0 2px 4px rgba(105, 105, 105, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
}

.selection-item {
  display: flex;
  min-height: 35px;
  width: fit-content;
  max-width: 700px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
}

.close-btn {
  left: 97%;
  position: absolute;
  cursor: pointer;
}

.lds-ring {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 50px;
  left: 50%;
  z-index: 100;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid lightgrey;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: lightgrey transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.isOkModal {
  position: fixed;
  top: 50px;
  left: 50%;
  width: 250px;
  height: 100px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(105, 105, 105, 0.15), 0 2px 4px rgba(105, 105, 105, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.confirm-btn {
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  background-color: #1890ff;
  cursor: pointer;
  outline: none;
}
