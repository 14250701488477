.point_history_item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cecece;

  &_info {
    display: flex;
  }

  .accuracy {
    flex: 1 0;
    text-align: center;
  }

  .linked_by {
    flex: 1 0;
  }

  .date {
    flex: 1 0;
    text-align: center;
    color: #a5a3a3;
  }

  .delete p {
    flex: 1 0;
    text-align: right;
    cursor: pointer;
    color: red;
  }
}
