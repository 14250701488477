.user_info {
  text-align: left;
  min-width: 300px;
  height: 100%;
}

.user_info .ant-card-meta-title {
  white-space: inherit;
}

.user_info .ant-card-meta {
  padding-bottom: 10px;
}

.user_info .ant-card-body {
  height: calc(100% - 47px);
}
