.data-left-panel {
  top: 100px;
  z-index: 1000;
  transform-origin: 100% 0;
  margin-bottom: 10px;
  margin-left: -20px;
  margin-top: -30px;

  Button {
    background: #e7e7e7;
  }
}

.data-left-panel-drawer {

  .ant-drawer-body {
    padding: 30px 5px 5px 5px;
  }

  Button.edit-button {
    padding: 5px 0px 0px 0px;
    margin-top: 20px;
    width: 40px;
    height: 40px;
  }

  .edit-switcher {
    width: 40px;
    margin-left: -2px;
  }
}
