.show-selection {
  padding: 10px;
  overflow: hidden;

  h1 {
    display: inline-block;
  }

  &-edit {
    margin: 0 10px;
    color: #b6b2b0;
  }

  &-content {
    display: grid;
    grid-template-columns: 6fr 4fr;

    .map-panel {
      width: calc(40% - 1.5em);
      left: calc(60% + 1em);
      height: calc(100% - 100px);
    }
  }
  &-button-group > * {
    margin: 0 10px 10px 10px;
  }
  &-user-select {
    margin: 20px 0 0 0;
    width: 300px;
    .ant-select {
      max-width: 330px;
      margin: 10px 0;
    }
  }
  &-user-list {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    max-width: 330px;
    margin-top: 20px;

    .ant-list-header {
      padding: 10px;
    }
    .ant-list-item {
      padding-left: 10px;
    }
  }
}

.selection-access {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;

  &-control {
    max-width: 320px;
    margin-right: 30px;
  }

  & > * {
    flex: 1 0 330px;
  }
}

.shadow-table {
  .ant-table-content {
    box-shadow: inset -6px 0px 10px 1px #dcdcdc;
  }
  .ant-table-fixed {
    box-shadow: inset -6px 0px 10px 13px white;
  }
}

.adv-search-download > * {
    margin: 0 10px;
}
