.user-card {
    text-align: left;
    margin-bottom: 10px;
}

.user-card .ant-card-head-title {
    white-space: inherit
}

.user-card .ant-card-extra button{
    background: transparent;
    border: none;
    cursor: pointer;
    color: #188fff;
}
.user-card .ant-card-extra button:hover,
.user-card .ant-card-extra button:focus {
    outline: none;
    opacity: 0.8;
}

.user-card.ant-card-type-inner .ant-card-head-title {
    padding: 16px 0;
}

.ant-card-type-inner .ant-card-body > * {
    margin-bottom: 10px;
}