.pagination {
  background: #F9F9F9;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 5px;

  .search_page_pagination {
    margin-top: 0;
    margin-left: auto;
    margin-right: 10px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;

    :global {
      .ant-pagination-item-active {
        background: #1890ff;

        a {
          color: white;
        }
      }
    }
  }
}

.show_num {
  display: flex;
  align-items: center;
  margin-left: 10px;

  span {
    color: #8A8A8A;
  }

  &_list {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 6px;
      text-decoration: underline;
      color: #545454;
      cursor: pointer;
      height: 24px;
      line-height: 22px;
      padding: 0 4px;

      &.active {
        border: 1px solid #1890ff;
        border-radius: 4px;
        font-weight: 500;
        background: #1890ff;
        color: #fff;
        text-decoration: none;
        padding: 0 4px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }
}

.num {
  color: #8A8A8A;
  margin-bottom: 20px;
  padding-left: 15px;

  span {
    color: #545454;
  }
}
