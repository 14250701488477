.leaflet-container {
  overflow: hidden;
  background-color: #fff !important;
  height: 100%;
}

.leaflet-default-icon-path {
  background-image: url('/public/marker.svg');
}

.grey {
  filter: grayscale(100%);
}

.leaflet-marker-icon.leaflet-div-icon {
  border: none;
  background: transparent;
}

.leaflet-control-geocoder-alternatives {
  width: 450px;
  height: 200px;
  overflow: auto;
}

.map-download-link {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.leaflet-control-easyPrint-button-export {
  background-image: url('./download.svg');
  background-size: 16px 16px;
  cursor: pointer;
}

.leaflet-bar a, .leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.site-tooltip-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.site-tooltip {
  min-width: 250px;
  max-width: 500px;
  overflow: hidden;
}

.site-tooltip-item {
  white-space: normal;
}

.site-tooltip-item span{
  font-weight: bold;
  margin-right: 5px;
}
