.research-list {

}

.research-list .ant-spin-container {
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  max-height: 300px;
  margin-bottom: 20px;
}

.research-list .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.research-list-access-edit {
  margin: 10px;
  float: right;
}
