.search-panel {
  text-align: left;
  width: 50%;
  height: 100%;
  padding: 10px 0 80px;
}

.search-panel-header {
  text-align: center;
  width: 80%;
  padding-left: 15px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.search-form.ant-form {
  max-width: 700px;
  padding-right: 10px;
  margin: 30px;
  margin-left: 10%;
}

form.search-form {
  div.ant-row.ant-form-item {
    div.ant-form-item-label {
      display: inline-table;
    }
  }
}

.search-form-button {
  width: 100%;
}

.search-page-pagination {
  margin-top: 20px;
}

.leaflet-control-geocoder-alternatives {
  li {
    white-space: normal;
  }

  li a {
    width: 100% !important;
    line-height: 14px !important;
  }
}
