.request_access {
  font-size: 14px;
  padding-bottom: 10px;

  &_text {
    flex: 1 0;
    padding: 5px;
  }
  
  .ant-tag-orange {
    font-size: 14px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: inherit;
    i {
      margin-right: 5px;
    }
  }

  &_is_send {

    i {
      margin-right: 5px;
    }
  }
}