.home-result-container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.home-result-container .left-panel {
  width: 48%;
  height: 800px;
}

.home-result-container .right-panel {
  width: 48%;
  height: 800px;
}

.left-panel .left-panel-block {
  width: 80%;
  height: 300px;
  margin: 20px auto;
  border: 1px solid lightgray;
}

.left-panel .left-panel-search {
  width: 80%;
  height: 50px;
  margin: 20px auto;
}

.left-panel-where .leaflet-tile-container {
  filter: grayscale(100%);
}

.right-panel  .ant-list-item:hover {
  background: #faf9f9;
}

.right-panel .list-item-avatar {
  margin-top: 10px;
}

a.list-item-link {
  color: #d4af84;
  font-weight: 600;
}

span.list-item-type {
  color: gray;
  font-weight: lighter;
  cursor: default;
  font-size: 12px;
}

span.list-item-type-value {
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 2px;
  font-size: 12px;
  color: white;
  cursor: default;
  font-weight: lighter;
  background-color: #d4af84;
}

.right-panel-top-text {
  font-size: 12px;
  color: gray;
  font-weight: lighter;
}

.right-panel-top-elem {
  margin-left: 7px;
  font-size: 12px;
  color: black;
  font-weight: normal;
}

.right-panel .ant-list-item {
  width: 100%;
}

.right-panel .ant-pagination-item-active {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active::selection {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active:hover {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active a {
  color: #d4af84;
}
.home-result-pagination-top {
  width: 50%;
  float: right;
  text-align: right;
}
.home-result-pagination-bot {
  text-align: right;
}
