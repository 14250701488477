.container.ling-about {
  padding: 10px;
}

.ling-about img {
  width: 100%;
  margin: 20px 0;
}

.ling-about p {
  text-indent: 1.5em;
  font-size: 16px;
}
