.slick-slider {
  margin: 30px;
}

.slick-prev:before, .slick-next:before {
  color: black;
}

.error-text {
  color: red;
  text-align: center;
}

.radiocarbon-summary img {
  width: 100%;
}
