.app-outer {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 30);
  padding-top: 30px;
}

.app-inner {
  height: 100%;
  width: 100%;
}

.tag-cloud {
  height: 100%;
  width: 100%;
}

.tag-cloud > div {
  cursor: pointer;
}

.tag-cloud > div:hover {
  opacity: 0.6;
}

.tag-item-wrapper {
  cursor: pointer;
}

.tag-item-wrapper:hover {
  z-index: 100;
}
