.map_legend {
  position: fixed;
  right: -5px;
  z-index: 1000;
  transform-origin: 100% 0;
  top: 100px;

  Button {
    background: #e7e7e7;
    padding-left: 3px;
    padding-right: 8px;
    margin-top: 20px;
  }
}

.map_legend_drawer {
  .ant-drawer-body{
    padding: 5px;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 10px;
    border: 0;
    overflow: hidden;
  }
  .legend_item {
    display: flex;
    align-items: center;
    margin: 5px 0;

    & > img {
      margin-right: 10px;
    }

    &_title {
      white-space: pre;
    }
  }
}
