.code_snippet {
  position: relative;

  pre {
    background: #ece8e8;
    padding: 10px;
    border-radius: 4px;
    padding-right: 30px;
  }

  &_button {
    position: absolute;
    top: 9px;
    right: 5px;
    background: white;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.8;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}