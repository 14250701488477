.ant-form-item-children .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
}

.ant-form-item-children .react-tel-input .flag-dropdown:hover {
    background-color: transparent;
    border: none;
}

.ant-form-item-children .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: transparent;
}

.ant-form-item-children .react-tel-input input[type=tel] {
    width: 268px;
}

.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content {
    width: 100%;
    box-sizing: content-box;
    padding: 0;
}

.account_confirm .ant-alert {
  margin-bottom: 10px;
}

.account_confirm {
  text-align: left;
}

.account_confirm .user-must-confirm {
  font-weight: bold;
}

.user-rol {
  text-align: right;
  padding: 0 20px;
  color: grey;
}

.user-rol .role {
  font-weight: bold;
  margin-left: 5px;
}

.ant-form-item-children {
  text-align: left;
}