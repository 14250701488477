#legend {
  position: absolute;
  top: 3px;
  left: 780px;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
  font-size: 1em;
  font-family: sans-serif;
  width: 165px;
  background: rgba(255,255,255,0.6);
}

.leaflet-marker-icon .label-text {
  opacity: 0;
  transition: all 0.3s;
}

.leaflet-marker-icon {
  overflow: hidden;
}

.leaflet-marker-icon:hover {
  overflow: inherit;
  z-index: 1000 !important;
}

.leaflet-marker-icon:hover .label-text {
  opacity: 1;
  alignment-baseline: middle;
  font-size: 12px;
  font-family: arial,helvetica,"sans-serif";
  fill: #000;
  font-weight: bold;
  text-shadow: 1px 1px 5px #fff;
}

.leaflet-marker-icon .label-line {
  opacity: 0;
  stroke-width: 1;
  stroke: #393939;
  transition: all 0.3s;
}

.leaflet-marker-icon:hover .label-line {
  opacity: 1;
}

.legendheading {
  position: relative;
  height: 25px;
  padding: 5px 2px 0px 2px;
  font-size: larger;
  font-weight: bold;
}
.legenditem {
  padding: 2px;
  margin-bottom: 2px;
}

svg.cluster-pie {
  overflow: initial;
}

/*Marker clusters*/
.marker-cluster-pie g.arc{
  fill-opacity: 0.5;
}
.marker-cluster-pie-label {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*Markers*/
.marker {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-radius:10px;
  margin-top: -10px;
  margin-left: -10px;
  border-style: solid;
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.marker div{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*marker categories*/
.category-epoch-0{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-epoch-1{
  fill: #ea00b6;
  stroke: #8B2286;
  background: #ea00b6;
  border-color: #8B2286;
}
.category-epoch-2{
  fill: #00D8FE;
  stroke: #00849f;
  background: #00D8FE;
  border-color: #00849f;
}
.category-epoch-3{
  fill: #FF3D19;
  stroke: #b22d13;
  background: #FF3D19;
  border-color: #b22d13;
}
.category-epoch-4{
  fill: #7E2ABB;
  stroke: #4b1c71;
  background: #7E2ABB;
  border-color: #4b1c71;
}
.category-epoch-5{
  fill: #0600F5;
  stroke: #0600b6;
  background: #0600F5;
  border-color: #0600b6;
}
.category-epoch-6{
  fill: #FF800C;
  stroke: #bc5e0a;
  background: #FF800C;
  border-color: #bc5e0a;
}
.category-epoch-7{
  fill: #8BE61B;
  stroke: #5c9f17;
  background: #8BE61B;
  border-color: #5c9f17;
}
.category-epoch-8{
  fill: #FFCA1C;
  stroke: #cb8f02;
  background: #FFCA1C;
  border-color: #cb8f02;
}
.category-0{
  fill: rgb(88, 165, 255);
  stroke: rgb(0, 69, 255);
  background: rgb(88, 165, 255);
  border-color: rgb(0, 69, 255);
}
.category-1{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
}
.category-artifact{
  fill: #FA0;
  stroke: #B60;
  background: #FA0;
  border-color: #B60;
}
.category-radiocarbon{
  fill: #FF3;
  stroke: #D80;
  background: #FF3;
  border-color: #D80;
}
.category-4{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
}
.category-5{
  fill: #9DF;
  stroke: #007;
  background: #9DF;
  border-color: #007;
}
.category-6{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-site{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-excavation{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
}
.category-research{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
}
.category-layer{
  fill: rgb(88, 165, 255);
  stroke: rgb(0, 69, 255);
  background: rgb(88, 165, 255);
  border-color: rgb(0, 69, 255);
}
.category-polygons{
  fill: #DEB887;
  stroke: #DEB887;
  background: #DEB887;
  border-color: #DEB887;
}
.category-culture{
  fill: #DB7093;
  stroke: #DB7093;
  background: #DB7093;
  border-color: #DB7093;
}
.category-assemblage{
  fill: #FFFAF0;
  stroke: #FFFAF0;
  background: #FFFAF0;
  border-color: #FFFAF0;
}
.category-lexeme{
  fill: #FF69B4;
  stroke: #FF69B4;
  background: #FF69B4;
  border-color: #FF69B4;
}
.category-term{
  fill: #BC8F8F;
  stroke: #BC8F8F;
  background: #BC8F8F;
  border-color: #BC8F8F;
}
.category-variant{
  fill: #FFFFE0;
  stroke: #FFFFE0;
  background: #FFFFE0;
  border-color: #FFFFE0;
}
.category-district{
  fill: #ADFF2F;
  stroke: #ADFF2F;
  background: #ADFF2F;
  border-color: #ADFF2F;
}
.category-locality{
  fill: #A0522D;
  stroke: #A0522D;
  background: #A0522D;
  border-color: #A0522D;
}
.category-person{
  fill: #2E8B57;
  stroke: #2E8B57;
  background: #2E8B57;
  border-color: #2E8B57;
}
.category-building{
  fill: #708090;
  stroke: #363f49;
  background: #708090;
  border-color: #363f49;
}
.category-encorganization{
  fill: #3CB371;
  stroke: #3CB371;
  background: #3CB371;
  border-color: #3CB371;
}

/*marker icons*/
/*.icon-1{*/
  /*!*background-image: url('running-16.png');*!*/
  /*!*background-repeat: no-repeat;*!*/
  /*!*background-position: 0px 1px;*!*/
/*}*/
/*.icon-2{*/
  /*background-image: url('bicycle-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/
/*.icon-3{*/
  /*background-image: url('motorcycle-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/
/*.icon-4{*/
  /*background-image: url('car-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/

/*Popup*/
.map-popup span.heading {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.map-popup span.attribute {
  display: block;
}
.map-popup span.label {
  font-weight: bold;
}
