$background-color: white;
$background-color-2: #e0c592;


.index-bg {
  top: 0;
  position: absolute;
  z-index: -1;
  background-color: #5b514c;
  width: 100vw;
  overflow: hidden;
  height: 456px;
  perspective: 4000px;

  .cd-floating-background {
    transform-style: preserve-3d;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.2s;
  }

  .index-bg-img {
    opacity: 0.18;
    width: 110%;
    height: 110%;
    object-fit: cover;
    left: -5%;
    position: relative;
    top: -5%;
  }
}

.index {
  main {
    color: #fff;
    max-width: 1200px;
    margin: 33px auto;
    padding: 0 15px;

    header {
      text-align: center;
    }
  }

  h1 {
    color: #fff;
    font-family: 'Roboto Slab', 'Roboto', serif;
    font-size: 48px;
    text-transform: uppercase;
  }

  .columns {
    display: flex;
    justify-content: space-between;
  }

  .short-column {
    width: 380px;
    display: inline-block;

    .tile {
      height: 245px;
    }
  }

  .wide-column {
    max-width: 775px;
    margin-right: 15px;
    flex: 1 2 775px;

    .tile {
      height: 505px;
    }
  }

  .tile {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    box-shadow: 0px 5px 20px rgba(91, 81, 76, 0.3);
    transition: all 0.3s;

    &:hover {
      box-shadow: 0px 5px 20px 4px rgba(91, 81, 76, 0.3);
    }

    &:hover img.svg {
      left: 10px;
    }

    img {
      top: inherit;
      bottom: 0;

      &.svg {
        width: 30px;
        position: relative;
        z-index: 1;
        background-color: transparent;
        margin-left: 5px;
        margin-top: -9px;
        left: 0;
        transition: all 0.3s;
      }
    }

    > a:hover {
      opacity: 0.5;
    }

    img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background-color: $background-color;
    }
  }

  .what {
    cursor: default;
    background-color: $background-color;
  }

  h3 {
    font-family: 'Roboto Slab', 'Roboto', sans-serif;
    text-transform: uppercase;
    float: left;
    font-size: 18px;
    color: $background-color;
    background-color: $background-color-2;
    display: inline-block;
    padding: 10px 30px;
  }

  .search-block {
    margin: 46px 0;

    &-select.ant-select {
      width: 25%;
    }

    &-input {
      width: 75%;
      top: 0;
      input:hover {
        border-color: white;
      }
    }

    .ant-select-selection--single {
      height: 46px;
      background-color: transparent;
      color: #fff;
    }

    .ant-select-open .ant-select-selection {
      box-shadow: none;
      border-color: #fff;
    }

    .ant-select-selection:focus, .ant-select-selection:active {
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
      border-color: #fff;
    }

    .ant-select-arrow {
      color: #fff;
      opacity: 0.6;
    }

    .ant-select-selection__rendered {
      line-height: 44px;
      margin-left: 30px;
    }

    .ant-input-search input {
      color: #fff;
      height: 46px;
      background-color: transparent;
      border-radius: 0;
    }
    .ant-input-search-enter-button .ant-btn-primary {
      background-color: $background-color-2;
      border-color: $background-color-2;
      width: 48px;
      height: 46px;
      border-radius: 0;
      transition: opacity 0.3s;
    }
  }
}

.index .search-block .ant-input-search-enter-button .ant-btn-primary:hover,
.index .search-block .ant-input-search-enter-button .ant-btn-primary:focus {
  opacity: 0.8;
}

.index .search-block .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection {
  border-radius: 0;
  border-right-width: 0 !important;
}

.index .search-block .ant-select-selection:hover,
.index .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fff;
  border-right-width: 0 !important;
}

.index .search-block .ant-input:focus {
  border-color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}


@media (max-width: 820px) {
  .dark.header-container > .header-main-link {
    display: block;
  }

  .dark.header-container .anticon {
    color: #b1acaa;
    display: block;
  }

  .dark.header-container {
    background-color: #5b514c;
  }

  .container {
    width: 100vw;
    overflow: hidden;
    margin-bottom: 0 !important;
  }

  .index-bg {
    display: none;
  }

  .index {
    main {
      width: 100%;
      color: #2d2d2d;
    }
    h1 {
      color: #2d2d2d;
      font-size: 18px;
    }
    .search-block {
      margin: 20px 0;

      &-input {
        width: 100%
      }
    }
    .search-block .ant-input-search input {
      color: #2d2d2d;
    }
    .columns {
      display: block;
    }
    .wide-column .tile {
      height: 245px;
    }


    .search-block .ant-select-selection:hover,
    .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
    .search-block .ant-input:focus,
    .search-block .ant-input:hover {
      border-color: #c0c0c0;
    }

    .wide-column,
    .short-column {
      width: 100%;
    }
  }

  .search-block .ant-select.ant-select-enabled {
    display: none;
  }

  span.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
    /* TODO rewrite inline styles on index page in search select */
    width: 100% !important;
  }

  .container__content {
    overflow: auto;
  }
}
