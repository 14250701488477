
.login-form.ant-form {
  max-width: 300px;
  padding: 10px;
  margin: 0 auto;
}

.login-form-button {
  width: 100%;
}

.footer_link {
  text-align: left;
  margin-top: 10px;
}

.footer_link a {
  display: block;
}
