// #root {
//   display: flex;
// }

.adv-search-field-input {
  &.input,
  &.select {
    flex: 1 0 0;
    margin: 10px;
  }
}

.adv-search-field{
  &.input {
    display: flex;
    align-items: center;

    .field-remove-button {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}