.result_table {

  &_sorter {
    padding: 10px;

    &_tag {
      padding: 5px 10px;
      cursor: pointer;

      i {
        font-size: 16px;
        vertical-align: text-bottom;
      }
    }
  }
}