.data-panel {
  text-align: left;
  float: left;
  width: 50%;
  height: 100%;
  padding: 10px 15px;
  position: relative;
  padding-bottom: 80px;
}

.data-panel-header {
  width: 100%;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 20px;

  .under-header {
    font-size: 14px;
    color: gray;
  }
}

.common-field-text a {
  margin-right: 10px;
}

.data-panel.source {
  width: 100%;
}

.data-panel.source .show_source{
  display: flex;
}

.data-panel.source .show_source .ant-list{
  width: 50%;
}

.data-panel.source .show_source .pdf {
  width: 50%;
  /* float: right; */
  border: 1px solid #d9d9d9;
  padding: 10px;
  text-align: center;
  margin: 0 10px;
}

.react-pdf__Page canvas {
  margin: auto;
}

.ant-collapse-header img {
  height: 16px;
  margin: 0 5px;
  position: relative;
  top: -1px;
}

i.layer_label {
  font-size: 12px;
}

.ant-list-item-meta-description .inline-links a{
  display: inline;
}

.common-field-text .ant-timeline {
  margin-top: 20px;
}

.edit-mod-switcher {
  position: absolute;
  top: 45px;
  right: 30px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: border 0.3s;
}

.ant-collapse-item .ant-tag {
  margin-left: 5px;
}
