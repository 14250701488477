.create-panel {
  text-align: left;
  width: 51%;
  height: 100%;
  padding: 10px 15px;
  margin-bottom: 60px;
}

.create-panel .ant-input-number-handler-wrap {
  width: 0px;
}

.create-panel .ant-form-item {
  margin-bottom: 10px;
}

.create-panel-modal .ant-form-item {
  margin-bottom: 10px;
}

.create-panel .ant-input-number {
  width: 40%;
  margin-right: 2%;
}

.create-panel-add-tab-modal {
  margin-left: 50%;
}

.create-panel-add-tab-modal .ant-modal-body {
  padding: 0px;
}

.create-panel-add-tab-modal .ant-list-item-meta-title {
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
}

.create-panel-add-tab-modal .ant-list-item {
  font-size: 16px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid lightgray;
}

.create-panel-add-tab-modal .ant-list-item:hover {
  cursor: pointer;
  background: lightgray;
}

.create-panel-header {
  max-width: 300px;
  padding-left: 15px;
  margin: 0 auto;
}

.create-form {
  .ant-form {
    padding-right: 100px;
  }
}

.create-form-button {
  margin-top: 30px;
  width: 100%;
  white-space: normal;
  height: auto;
  min-height: 32px;
}

.create-panel-modal-header {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.auto-input-option-new {
  color: #888;
  font-weight: 400;
}

.create-panel-relations-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}

.create-panel-lower-title {
  font-size: 16px;
}

.ant-select-dropdown-menu-item {
  white-space: normal;
}

.create-panel-modal .ant-progress-inner {
  background: white;
}

.create-panel-modal .anticon-spin {
  display: none;
}

.add-interactive-marker-button {
  background: white;
}

.add-interactive-marker-button i {
  color: rgba(0, 0, 0, .5);
}

.addNewTab {
  margin-right: 10px;
  background-color: #1890ff;
  color: white;
}
