.source-list {
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  margin-bottom: 20px;
}

.source-list .ant-list-item-meta-description span{
  display: block;
}

.ant-table-tbody a {
  display: block;
}