.researcher_container .form_container{
  max-width: 500px;
  padding: 5px 20px;
  margin: 0 auto;
}

.form_container .ant-form-item {
  margin-bottom: 0px;
}

.form_container .ant-btn {
  margin: 10px 0;
}
