.adv-search-block {
  margin-top: 10px;
  position: relative;

  &-body {
    border-left: 2px solid #3690ff;
    position: relative;
    padding: 0 25px;
    margin-top: 10px;
  }

  .field-add-button {
    margin-right: 10px;
  }
}

.ant-radio-group {
  display: block;
}